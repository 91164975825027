<template>
  <div>
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
    <div v-show="!isSpinner">

      <!-- <user-list-add-new
        :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
        :role-options="roleOptions"
        :plan-options="planOptions"
        @refetch-data="refetchData"
      /> -->

      <!-- Top Info -->
      <b-card v-if="collegeResponse && Object.keys(collegeResponse).length">
        <b-row>
          <b-col md="3">
            <b-form-group label="College Name">
              <span class="output_span">{{collegeResponse.collegeName}}</span>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="University">
              <span class="output_span">{{collegeResponse.universityName}}</span>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="No. of Courses">
              <span class="output_span">{{totalUsers}}</span>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Address">
              <span class="output_span">{{collegeResponse.addressLine1}}{{collegeResponse.addressLine2?', ':''}}{{collegeResponse.addressLine2}}</span>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Country" v-if="country">
              <span class="output_span">{{collegeResponse.country.name}}</span>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="State" v-if="state">
              <span class="output_span">{{collegeResponse.state.name}}</span>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="City" v-if="city">
              <span class="output_span">{{collegeResponse.city.name}}</span>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Pincode">
              <span class="output_span"> {{collegeResponse.pincode}}</span>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <!-- Table -->
      <b-card>
        <!-- Filters -->
        <b-card title="Filters" style="margin-bottom:0px;padding:botton:0px;" v-if="false">
          <b-row>
            <b-col md="3">
              <b-form-group label="Field">
                <b-form-select
                    v-model="filterCountry"
                    :options="countryOptions"
                    placeholder="Select"
                    @change="getFilterStates()"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Course">
                <b-form-select
                    v-model="filterState"
                    :options="stateOptions"
                    placeholder="Select"
                    @change="getFilterCities()"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Intake">
                <b-form-select
                  v-model="filterCity"
                  :options="filterIntakeOptions"
                  placeholder="Select"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Exams">
                <b-form-select
                  v-model="filterCity"
                  :options="filterExamOptions"
                  placeholder="Select"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="German Language">
                <b-form-select
                  v-model="filterCity"
                  :options="filterGermanLanguageOptions"
                  placeholder="Select"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Mode of Application">
                <b-form-select
                  v-model="filterCity"
                  :options="filterModeOfApplicationOptions"
                  placeholder="Select"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >

          <div class="">

            <!-- Table Top -->
            <b-row>

              <!-- Search -->
              <b-col
                cols="12"
                md="6"            
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0 "
              >
                <h4 class="mb-0 table_title">Course Listing</h4>
              </b-col>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0 top_btn_right"
              >
                <b-button variant="primary" v-b-modal.add-course style="margin-right:1.5rem;">
                  Add Course
                </b-button>
                <!-- <b-button variant="primary" @click="callUpdateFunction" style="margin-right:1.5rem;">
                  Update In DB
                </b-button> -->
                
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <!-- <label>entries</label> -->
              </b-col>
            </b-row>

          </div>
          
          <b-table
            ref="refUserListTable"
            class="position-relative college_info_table mt-2"
            :items="fetchedData"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :show-empty="!isSpinner"
            hover           
            @row-clicked="getCourseDetail"
            empty-text="No matching records found"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            no-local-sorting
            @sort-changed="handleSortChange"
          >
            <!-- Column: No. -->
            <template #cell(no)="data">
              <div>
                  <span>{{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}</span>
              </div>
            </template>

            <!-- Column: Field Name -->
            <template #cell(field_name)="data">
              <div>
                  <span v-if="data.item.field_name">{{data.item.field_name.name}}</span>
              </div>
            </template>
            <template #cell(course_name)="data">
              <div>
                  <span v-if="data.item.course_name">{{data.item.course_name.title||data.item.course_name}}</span>
              </div>
            </template>

            <!-- Column: Intake -->
            <template #cell(intake)="data">
              <div v-for="(val,index) in data.item.intake" :key="index">
                <span>{{val}}</span>
              </div>
            </template>

            <!-- Column: Application Deadline -->
            <template #cell(application_deadline)="data">
              <div v-for="(val,index) in data.item.application_deadline" :key="index">
                <!-- <span>{{val.date.split("-")[2]}} {{months[val.date.split("-")[1]-1]}}, {{val.date.split("-")[0]}}</span> -->
                <span>{{ dateFormatWithMoment(new Date(val.date),'DD MMM')}}</span>
              </div>
            </template>

            <!-- Column: Exams -->
            <template #cell(exams)="data">
              <div v-for="(val,index) in data.item.exams" :key="index">
                <span>{{val.title}}</span>
              </div>
            </template>

            <!-- Column: Band Scores -->
            <template #cell(band_scores)="data">
              <div v-for="(val,index) in data.item.band_scores" :key="index">
                <span>{{parseFloat(val.score).toFixed(1)}}</span>
              </div>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <!-- <div style="white-space: nowrap" v-if="$route.meta.action=='write' || $route.meta.action==undefined"> -->
              <div style="white-space: nowrap;display:flex;justify-content: end;align-items: center;" @click.stop.prevent>   
                <!-- <img 
                v-if="$route.meta.action=='write' || $route.meta.action==undefined"
                src='@/assets/images/erflog/Edit.png' 
                @click.prevent="selectedCourse=data,selectedCourseInd=data.index,getCourseDetail(data,data.index)" 
                v-b-modal.detail-course
                class="action_icon"/> -->
                <img 
                v-if="($route.meta.action=='write' || $route.meta.action==undefined) && !data.item.isDeleted"
                src='@/assets/images/erflog/Edit.png' 
                @click.prevent="selectedCourse=data,getCourseData(data.item,data.index)" 
                v-b-modal.edit-course 
                class="action_icon"/>
                <!-- <div > -->
                <!-- <img     
                v-if="($route.meta.action=='write' || $route.meta.action==undefined) && data.item.isDeleted"            
                src='@/assets/images/erflog/disable_new.png'
                style="cursor:pointer !important" 
                @click.prevent="selectedCourse=data,deleteCourse=true" 
                v-b-modal.delete-Course 
                class="action_icon"/>
                <img     
                v-if="($route.meta.action=='write' || $route.meta.action==undefined) && !data.item.isDeleted"            
                src='@/assets/images/erflog/enable_new.png'
                style="cursor:pointer" 
                @click.prevent="selectedCourse=data,deleteCourse=true" 
                v-b-modal.delete-Course 
                class="action_icon"/> -->
                <b-icon-toggle-off class="action_icon focusRemoveToggle" variant="secondary" width="30px" height="22px" v-if="($route.meta.action=='write' || $route.meta.action==undefined) && data.item.isDeleted" style="cursor:pointer !important" @click.prevent="selectedCourse=data,deleteCourse=true" v-b-modal.delete-Course></b-icon-toggle-off>
                <b-icon-toggle-on class="action_icon focusRemoveToggle" variant="success" width="30px" height="22px" v-if="($route.meta.action=='write' || $route.meta.action==undefined) && !data.item.isDeleted" style="cursor:pointer" @click.prevent="selectedCourse=data,deleteCourse=true" v-b-modal.delete-Course></b-icon-toggle-on>
                <!-- </div> -->
               
                <img 
                v-show="data.item.remark!==''"
                src="@/assets/images/erflog/info_icon.png" 
                style="cursor:pointer"                
                class="action_icon"
                v-b-tooltip.hover.left="data.item.remark"
                />              
              </div>
              
            </template>

          </b-table>
          <div class="mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalUsers"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  @change="handlePageChange"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-card>

      <!-- Edit College -->
      <b-modal
        id="edit-college"
        cancel-variant="outline-secondary"
        ok-title="Save"
        cancel-title="Cancel"
        centered
        size="lg"
        title="Edit College"
        :no-close-on-backdrop="true"
        @hidden="selectedCourse='',resetData()"
        @ok="(e)=>{e.preventDefault();editSelectedCollege(e);}" 
        :ok-disabled="modalSppiner"
        :cancel-disabled="modalSppiner"       
      >
        <validation-observer ref="personalInfoForm">
          <!-- form -->
          <b-form>
             <b-spinner variant="primary" v-if="modalSppiner" large class="pageLoadSpinners"/>
            <b-row>
              <!-- Name -->
              <b-col md="6">
                  <b-form-group label="College Name">
                  <validation-provider
                      #default="{ errors }"
                      name="college name"
                      rules="required"
                  >
                      <b-form-input
                          v-model="collegeName"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                          :disabled='true'
                          @blur="collegeName=collegeName.trim()"
                      />
                      <!-- <small class="text-danger" v-if="!duplicateEditCollegeName">{{ errors[0] }}</small>
                      <small class="text-danger" v-else>College already exists.</small> -->
                  </validation-provider>
                  </b-form-group>
              </b-col>
              <!-- Name -->
              <b-col md="6">
                  <b-form-group label="University Name">
                  <validation-provider
                      #default="{ errors }"
                      name="university name"
                      rules="required"
                  >
                    <b-form-input
                          v-model="universityName"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                          :disabled='true'
                          @blur="universityName=universityName.trim()"
                      />
                      <!-- <b-form-select
                          v-model="universityName"
                          :options="universityOptions"
                          :disabled='true'
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                      /> -->
                      <small class="text-danger" >{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Address Line 1 -->
              <b-col md="6">
                  <b-form-group label="Address Line 1">
                  <validation-provider
                      #default="{ errors }"
                      name="address line 1"
                      rules="required"
                  >
                      <b-form-input
                          v-model="addressLine1"
                          :disabled='modalSppiner'
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                          @blur="addressLine1=addressLine1.trim()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Address Line 2 -->
              <b-col md="6">
                  <b-form-group label="Address Line 2">
                  <validation-provider
                      #default="{ errors }"
                      name="address line 2"
                      rules="required"
                  >
                      <b-form-input
                          v-model="addressLine2"
                          :disabled='modalSppiner'
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                          @blur="addressLine2=addressLine2.trim()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Country -->
              <b-col md="6">
                  <b-form-group label="Country">
                  <validation-provider
                      #default="{ errors }"
                      name="country"
                      rules="required"
                  >
                      <b-form-select
                          v-model="country"
                          :disabled='modalSppiner'
                          :options="countryOptions"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                          @change="getStates()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- State -->
              <b-col md="6">
                  <b-form-group label="State">
                  <validation-provider
                      #default="{ errors }"
                      name="state"
                      rules="required"
                  >
                      <b-form-select
                          v-model="state"
                          :options="stateOptions"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                          :disabled="!country||modalSppiner"
                          @change="getCities()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- City -->
              <b-col md="6">
                  <b-form-group label="City">
                  <validation-provider
                      #default="{ errors }"
                      name="city"
                      rules="required"
                  >
                      <b-form-select
                          v-model="city"
                          :disabled="!state||modalSppiner"
                          :options="cityOptions"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Pincode -->
              <b-col md="6">
                  <b-form-group label="Pincode">
                  <validation-provider
                      #default="{ errors }"
                      name="pincode"
                      rules="required"
                  >
                      <b-form-input
                        v-model="pincode"
                        :disabled='modalSppiner'
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Pincode"
                        @blur="pincode=pincode.trim()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>


      <!-- Add Course -->
      <b-modal
        id="add-course"
        cancel-variant="outline-secondary"
        ok-title="Save"
        cancel-title="Cancel"
        centered
        size="lg"
        title="Add Course"
        :no-close-on-backdrop="true"
        @hidden="selectedCourse='',resetCorseData(true),existCourseName=''"
        @ok="(e)=>{e.preventDefault();addCourse(e)}"
        :ok-disabled='modalSppiner'
        :cancel-disabled='modalSppiner'
      >
        <validation-observer ref="addCourseForm">
          <!-- form -->
          <b-form>
            <b-spinner variant="primary" v-if="modalSppiner" large class="pageLoadSpinners"/>
            <b-row>
              <!-- Field -->
              <b-col md="4">
                  <b-form-group label="Field">
                  <validation-provider
                      #default="{ errors }"
                      name="field"
                      rules="required"
                  >
                      <b-form-select
                        v-model="fieldName"
                        :options="fieldOptions"
                        :disabled='modalSppiner'
                        placeholder="Select"
                        @change="courseOptions=fieldName.courses,course=[]"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Course -->
              <b-col md="4">
                  <b-form-group label="Course">
                  <validation-provider
                      #default="{ errors }"
                      name="course"
                      rules="required"
                  >
                      <v-select
                        v-model="course"
                        label="title"
                        multiple
                        :options="courseOptions"
                        @input="courseSameNameValidation"
                        :state="errors.length > 0 ? false:null"
                        :disabled="!fieldName || modalSppiner"
                        placeholder="Select"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small class="text-danger" v-if='existCourseName!==""'>Course name already exists.</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Mode of Application -->
              <b-col md="4">
                  <b-form-group label="Mode of Application">
                  <validation-provider
                    #default="{ errors }"
                    name="mode of application"
                    rules="required"
                  >
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="modeOfApplication"
                        plain
                        :disabled='modalSppiner'
                        name="mode of application"
                        value="Uniassist"
                      >
                        Uniassist
                      </b-form-radio>
                      <b-form-radio
                        v-model="modeOfApplication"
                        plain
                        :disabled='modalSppiner'
                        name="mode of application"
                        value="Direct"
                      >
                        Direct
                      </b-form-radio>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>
            <!-- </b-row> -->
            <!-- <b-row> -->
              <!-- Exams -->
              <b-col md="4">
                  <b-form-group label="Exams">
                  <validation-provider
                    #default="{ errors }"
                    name="exams"
                    rules="required"
                  >
                    <v-select
                      v-model="exams"
                      label="title"
                      multiple
                      :disabled='modalSppiner'
                      :options="examOptions"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Band Scores -->
              <b-col md="4" v-for="(data,index) in exams" :key="index">
                  <b-form-group :label="data.title">
                  <validation-provider
                    #default="{ errors }"
                    :name="data.title"
                    rules="required"
                  >
                    <v-select
                      v-model="data.score"
                      label="title"
                      :multiple="false"
                      :disabled='modalSppiner'
                      :options="bandScoreOptions"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Select"
                    />
                    <!-- <b-form-input
                      v-model="data.score"
                      :disabled='modalSppiner'
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter Number"
                      @blur="data.score=data.score.trim()"
                    /> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Language -->
              <!-- <b-col md="4">
                  <b-form-group label="Language">
                  <validation-provider
                    #default="{ errors }"
                    name="language"
                    rules="required"
                  >
                    <b-form-select
                      v-model="language"
                      :options="languageOptions"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col> -->

              <!-- German Language -->
              <b-col md="4">
                  <b-form-group label="German Language">
                  <validation-provider
                    #default="{ errors }"
                    name="german language"
                    rules="required"
                  >
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="germanLanguage"
                        :disabled='modalSppiner'
                        plain
                        name="german language"
                        value="None"
                      >
                        None
                      </b-form-radio>
                      <b-form-radio
                        v-model="germanLanguage"
                        plain
                        :disabled='modalSppiner'
                        name="german language"
                        value="A1"
                      >
                        A1
                      </b-form-radio>
                      <b-form-radio
                        v-model="germanLanguage"
                        plain
                        :disabled='modalSppiner'
                        name="german language"
                        value="A2"
                      >
                        A2
                      </b-form-radio>
                      <b-form-radio
                        v-model="germanLanguage"
                        plain
                        :disabled='modalSppiner'
                        name="german language"
                        value="B1"
                      >
                        B1
                      </b-form-radio>
                      <b-form-radio
                        v-model="germanLanguage"
                        plain
                        :disabled='modalSppiner'
                        name="german language"
                        value="B2"
                      >
                        B2
                      </b-form-radio>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Intake -->
              <b-col md="4">
                  <b-form-group label="Intake">
                  <validation-provider
                      #default="{ errors }"
                      name="intake"
                      rules="required"
                  >
                    <b-form-checkbox-group
                      id="intake-1"
                      v-model="intake"
                      :disabled='modalSppiner'
                      name="intake"
                      class="demo-inline-spacing"
                      @change="getIntakes()"
                    >
                      <b-form-checkbox value="WS">
                        WS
                      </b-form-checkbox>
                      <b-form-checkbox value="SS">
                        SS
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Intake Application Date -->
              <b-col md="4" v-for="(data,index) in applicationDeadline" :key="'applicationDeadline'+index">
                  <b-form-group :label="data.title+' Application Deadline'">
                  <validation-provider
                    #default="{ errors }"
                    :name="data.title+' Application Deadline'"
                    rules="required"
                  >
                    <b-form-input
                        id="example-input"
                        v-model="data.date"
                        type="date"
                        :disabled='modalSppiner'
                        @change="changeBirthDate($event,index)"
                        placeholder="YYYY-MM-DD"
                        autocomplete="off"
                        show-decade-nav
                        :state="errors.length > 0 ? false:null"
                        class="input-date"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Application Fees -->
              <b-col md="4">
                  <b-form-group label="Application Fees">
                  <validation-provider
                    #default="{ errors }"
                    name="application fees"
                    rules="required"
                  >
                    <b-form-input
                      v-model="applicationFees"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter Number"
                      :disabled='modalSppiner'
                      @keypress="isOnlyDecimal"
                      @blur="applicationFees=applicationFees.trim()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Tution Fees -->
              <b-col md="4">
                  <b-form-group label="Tution Fees">
                  <validation-provider
                    #default="{ errors }"
                    name="tution fees"
                    rules="required"
                  >
                    <b-form-input
                      v-model="tutionFees"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter Number"
                      :disabled='modalSppiner'
                      @keypress="isOnlyDecimal"
                      @blur="tutionFees=tutionFees.trim()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Remark -->
              <b-col md="12">
                  <b-form-group label="Remark">
                  <validation-provider
                    #default="{ errors }"
                    name="remark"
                    rules=""
                  >
                    <b-form-textarea
                      v-model="remark"
                      name="remark"
                      no-resize
                      :disabled='modalSppiner'
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter Text"
                      @blur="remark=remark.trim()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>

      <!-- DETAIL COURSE -->
       <b-modal
        id="detail-course"
        cancel-variant="outline-secondary"
        ok-title="Edit"
        centered
        size="lg"
        ok-only
        title="Detail"
        :no-close-on-backdrop="true"
        :ok-disabled="(modalSppiner || $route.meta.action=='read') && (editCourseModal && editCourseModal.isDeleted == true)"
        @ok='getCourseData(editCourseModal,selectedCourse.index)'                
      >
        <validation-observer v-if='detailCourse' ref="addCourseForm">
          <!-- form -->
          <b-form>
            <b-spinner variant="primary" v-if="modalSppiner" large class="pageLoadSpinners"/>
            <b-row>              
              <!-- Field -->
              <b-col md="4">
                  <b-form-group label="Field">
                    {{detailCourse.field}}                 
                  </b-form-group>
              </b-col>

              <!-- Course -->
              <b-col md="4">
                  <b-form-group label="Course">
                    {{detailCourse.course.name}}                
                  </b-form-group>
              </b-col>

              <!-- Mode of Application -->
              <b-col md="4">
                  <b-form-group label="Mode of Application">
                    {{detailCourse.moa}}
               </b-form-group>
              </b-col>
            <!-- </b-row> -->

            <!-- <b-row> -->    
              <!-- Band Scores -->
              <b-col md="4" v-for="(data,index) in detailCourse.exams" :key="index">
                  <b-form-group :label="data.title+' Band Socres'">
                    {{data.score}}               
                  </b-form-group>
              </b-col>

              <!-- ADDMISSION FEES -->
              <b-col md="4">
                  <b-form-group label="Admission Fees(₹)">
                    {{detailCourse.assmi_fee}}                 
                  </b-form-group>
              </b-col> 

              <!-- ADDMISSION FEES -->
              <b-col md="4">
                  <b-form-group label="Tuition Fees(₹)">
                    {{detailCourse.tution_fee}}                 
                  </b-form-group>
              </b-col> 

              <!-- German Language -->
              <b-col md="4">
                  <b-form-group label="German Language">
                    {{detailCourse.gl}}                 
                  </b-form-group>
              </b-col>

              <!-- Intake -->
              <b-col md="4">
                  <b-form-group label="Intake" style="display: flex;">
                    <span v-for='(data,index) in detailCourse.intake' :key="index">
                      {{data.title}}
                      <span v-if='index !== Number(detailCourse.intake.length) - 1'>,</span>
                    </span>           
                  </b-form-group>
              </b-col>

              <!-- Intake Application Date -->
              <b-col md="4" v-for="(data,index) in detailCourse.intake" :key="'applicationDeadline'+index">
                  <b-form-group :label="data.title+' Application Deadline'">
                    {{data.date}}                 
                  </b-form-group>
              </b-col>
              <!-- Remark -->
              <b-col md="12">
                  <b-form-group label="Remark">
                    {{detailCourse.remark}}                
                  </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>


      <!-- Edit Course -->
      <b-modal
        id="edit-course"
        cancel-variant="outline-secondary"
        ok-title="Save"
        cancel-title="Cancel"
        centered
        size="lg"
        title="Edit Course"
        :no-close-on-backdrop="true"
        @hidden="selectedCourse='',resetCorseData(true)"
        @ok="(e)=>{e.preventDefault();editSelectedCourse(e)}"
        :ok-disabled='modalSppiner'
        :cancel-disabled='modalSppiner'
      >
        <validation-observer ref="addCourseForm">
          <!-- form -->
          <b-form>
            <b-spinner variant="primary" v-if="modalSppiner" large class="pageLoadSpinners"/>
            <b-row>              
              <!-- Field -->
              <b-col md="4">
                  <b-form-group label="Field">
                  <validation-provider
                      #default="{ }"
                      name="field"
                      rules=""
                  >
                  <b-form-input
                    v-if="fieldName"
                    v-model="fieldName.name"
                    :disabled='true'
                  >
                  </b-form-input>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Course -->
              <b-col md="4">
                  <b-form-group label="Course">
                  <validation-provider
                      #default="{ }"
                      name="course"
                      rules=""
                  >
                  <b-form-input
                    v-if="courseName"
                    v-model="courseName.title"
                    :disabled='true'
                  >
                  </b-form-input>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Mode of Application -->
              <b-col md="4">
                  <b-form-group label="Mode of Application">
                  <validation-provider
                    #default="{ errors }"
                    name="mode of application"
                    rules="required"
                  >
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="modeOfApplication"
                        plain
                        :disabled='modalSppiner'
                        name="mode of application"
                        value="Uniassist"
                      >
                        Uniassist
                      </b-form-radio>
                      <b-form-radio
                        v-model="modeOfApplication"
                        plain
                        :disabled='modalSppiner'
                        name="mode of application"
                        value="Direct"
                      >
                        Direct
                      </b-form-radio>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>
            <!-- </b-row> -->

            <!-- <b-row> -->
              <!-- Exams -->
              <b-col md="4">
                  <b-form-group label="Exams">
                  <validation-provider
                    #default="{ errors }"
                    name="exams"
                    rules="required"
                  >
                    <v-select
                      v-model="exams"
                      label="title"
                      multiple
                      :disabled='modalSppiner'
                      :options="examOptions"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Band Scores -->
              <b-col md="4" v-for="(data,index) in exams" :key="index">
                  <b-form-group :label="data.title">
                  <validation-provider
                    #default="{ errors }"
                    :name="data.title"
                    rules="required"
                  >
                    <v-select
                      v-model="data.score"
                      label="title"
                      :multiple="false"
                      :disabled='modalSppiner'
                      :options="bandScoreOptions"
                      :state="errors.length > 0 ? false:null"
                      @change="changeOptionsValue()"
                      placeholder="Select"
                    />
                    <!-- <b-form-input
                      v-model="data.score"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter Number"
                      :disabled='modalSppiner'
                      @change="changeOptionsValue()"
                      @blur="data.score=data.score.trim()"
                    /> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Language -->
              <!-- <b-col md="4">
                  <b-form-group label="Language">
                  <validation-provider
                    #default="{ errors }"
                    name="language"
                    rules="required"
                  >
                    <b-form-select
                      v-model="language"
                      :options="languageOptions"
                      placeholder="Select"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col> -->

              <!-- German Language -->
              <b-col md="4">
                  <b-form-group label="German Language">
                  <validation-provider
                    #default="{ errors }"
                    name="german language"
                    rules="required"
                  >
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="germanLanguage"
                        plain
                        :disabled='modalSppiner'
                        name="german language"
                        value="None"
                      >
                        None
                      </b-form-radio>
                      <b-form-radio
                        v-model="germanLanguage"
                        plain
                        :disabled='modalSppiner'
                        name="german language"
                        value="A1"
                      >
                        A1
                      </b-form-radio>
                      <b-form-radio
                        v-model="germanLanguage"
                        plain
                        :disabled='modalSppiner'
                        name="german language"
                        value="A2"
                      >
                        A2
                      </b-form-radio>
                      <b-form-radio
                        v-model="germanLanguage"
                        plain
                        :disabled='modalSppiner'
                        name="german language"
                        value="B1"
                      >
                        B1
                      </b-form-radio>
                      <b-form-radio
                        v-model="germanLanguage"
                        plain
                        :disabled='modalSppiner'
                        name="german language"
                        value="B2"
                      >
                        B2
                      </b-form-radio>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Intake -->
              <b-col md="4">
                  <b-form-group label="Intake">
                  <validation-provider
                      #default="{ errors }"
                      name="intake"
                      rules="required"
                  >
                    <b-form-checkbox-group
                      id="intake-1"
                      v-model="intake"
                      name="intake"
                      :disabled='modalSppiner'
                      class="demo-inline-spacing"
                      @change="getIntakes()"
                    >
                      <b-form-checkbox value="WS">
                        WS
                      </b-form-checkbox>
                      <b-form-checkbox value="SS">
                        SS
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Intake Application Date -->
              <b-col md="4" v-for="(data,index) in applicationDeadline" :key="'applicationDeadline'+index">
                  <b-form-group :label="data.title+' Application Deadline'">
                  <validation-provider
                    #default="{ errors }"
                    :name="data.title+' Application Deadline'"
                    rules="required"
                  >
                    <b-form-input
                      id="example-input"
                      v-model="data.date"
                      type="date"
                      placeholder="YYYY-MM-DD"
                      :disabled='modalSppiner'
                      autocomplete="off"
                      show-decade-nav
                      @change="changeBirthDate($event,index)"
                      :state="errors.length > 0 ? false:null"
                      class="input-date"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Application Fees -->
              <b-col md="4">
                <b-form-group label="Application Fees">
                <validation-provider
                  #default="{ errors }"
                  name="application fees"
                  rules="required"
                >
                  <b-form-input
                    v-model="applicationFees"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter Number"
                    :disabled='modalSppiner'
                    @keypress="isOnlyDecimal"
                    @blur="applicationFees=applicationFees.trim()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Tution Fees -->
              <b-col md="4">
                <b-form-group label="Tution Fees">
                <validation-provider
                  #default="{ errors }"
                  name="tution fees"
                  rules="required"
                >
                  <b-form-input
                    v-model="tutionFees"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter Number"
                    :disabled='modalSppiner'
                    @keypress="isOnlyDecimal"
                    @blur="tutionFees=tutionFees.trim()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Remark -->
              <b-col md="12">
                  <b-form-group label="Remark">
                  <validation-provider
                    #default="{ errors }"
                    name="remark"
                    rules=""
                  >
                    <b-form-textarea
                      v-model="remark"
                      name="remark"
                      no-resize
                      :disabled='modalSppiner'
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter Text"
                      @blur="remark=remark.trim()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>

      <!-- Delete Course -->
      <b-modal
        id="delete-Course"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="No"
        centered
        title=""
        :no-close-on-backdrop="true"
        @hidden="selectedCourse='',deleteCourse=false"
        v-if="selectedCourse!='' && deleteCourse==true"
        @ok="deleteFieldMethod(selectedCourse)"
      >
        <div data-v-3b7711cd="" class="create_account_img">
          <img data-v-3b7711cd="" src="@/assets/images/erflog/Approve_Popup.png" alt="">
        </div>
        <div style="text-align: center;" v-if="selectedCourse!=''">
          <label class="s_modal_title" v-if='!selectedCourse.item.isDeleted'>Disable Course</label>
          <label class="s_modal_title" v-if='selectedCourse.item.isDeleted'>Enable Course</label>
          <p v-if='!selectedCourse.item.isDeleted'>Are you sure you want to disable {{selectedCourse.item.field_name.name}}?</p>
          <p v-if='selectedCourse.item.isDeleted'>Are you sure you want to enable {{selectedCourse.item.field_name.name}}?</p>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BFormCheckbox,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormGroup,BFormRadio,BFormDatepicker,
  BForm,BFormTextarea,BFormSelect,BFormCheckboxGroup,BSpinner, VBTooltip,BIconToggleOn,BIconToggleOff
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useCollegeCourseList'
//vee-validator
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'

import { Country, State, City }  from 'country-state-city';

import { required,min_value,max_value,numeric } from 'vee-validate/dist/rules';

extend('required',required)
extend('min_value',min_value)
extend('max_value',max_value)
extend('numeric',numeric)

import firebase from '@/utils/firebaseInit'
import firebaseTimeStamp from 'firebase'
import { dbCollections } from '@/utils/firebaseCollection'

const db = firebase.firestore()

import Ripple from 'vue-ripple-directive'

import { ValidationFunction } from '@/utils/globalValidations.js';
// import {updateQueryForCourses}  from '@/utils/updateFieldsOnDb.js';
// import {updateQueryForApplication}  from '@/utils/UpdateFieldInApplication.js';

import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import moment from 'moment';
import { getCollectionData } from '@/utils/dynamicGetDataQuery'
import { getCollectionDataTypesense } from '@/utils/Typesense/queries'
export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  }, 
  components: {    
    ValidationProvider, 
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormGroup,
    BFormRadio,
    BFormDatepicker,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    vSelect,
    BSpinner,
    BIconToggleOff,
    BIconToggleOn
  },
  data(){
    return{
      bandScoreOptions:[
        {title:4,value:"4"},
        {title:4.5,value:"4.5"},
        {title:5,value:"5"},
        {title:5.5,value:"5.5"},
        {title:6,value:"6"},
        {title:6.5,value:"6.5"},
        {title:7,value:"7"},
        {title:7.5,value:"7.5"},
        {title:8,value:"8"},
        {title:8.5,value:"8.5"},
        {title:9,value:"9"}
      ],

      deleteCourse:false,

      detailCourse:{
        'field':'',
        'course':'',
        'moa':'',
        'exams':[],
        'gl':'',
        'assmi_fee':'',
        'tution_fee':'',
        'intake':[],
        'remark':''
      },
      editCourseModal:false,
      modalSppiner:false,
      months:["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],

      filterFieldsOptions:[{value:'',text:'All'}],
      filterCourseOptions:[{value:'',text:'All'}],
      filterIntakeOptions:[
        {value:'',text:'All'},
        {value:'WS',text:'WS'},
        {value:'SS',text:'SS'},
      ],
      filterExamOptions:[
        {value:'',text:'All'},
        {value:'All',text:'All'},
        {value:'All',text:'All'},
        {value:'All',text:'All'},
        {value:'All',text:'All'},
      ],
      filterGermanLanguageOptions:[
        {value:'',text:'All'},
        {value:'None',text:'None'},
        {value:'A1',text:'A1'},
        {value:'A2',text:'A2'},
        {value:'B1',text:'B1'},
        {value:'B2',text:'B2'},
      ],
      filterModeOfApplicationOptions:[
        {value:'',text:'All'},
        {value:'Uniassist',text:'Uniassist'},
        {value:'Direcrt',text:'Direct'}
      ],

      
      collegeName:'',
      courses:'',
      universityName:null,
      addressLine1:'',
      addressLine2: '',
      pincode:'',
      state:null,
      city:null,
      country:null,
      countryOptions: [{value:null,text:'Select'}],
      stateOptions: [{value:null,text:'Select'}],
      cityOptions: [{value:null,text:'Select'}],

      universities:[],
      // colleges:[],
      // universityOptions:[{value:null,text:'Select'}],
      selectedCourse:'',
      selectedCourseInd:'',   
      docId:this.$route.params.id,

      clgOldName:'',


      applicationFees:'',
      tutionFees:'',


      //Course Details
      fieldName:null,
      fieldOptions: [{value:null,text:'Select'}],
      fieldOptionsEdit: [{value:null,text:'Select'}],
      course:[],
      courseName:{},
      oldCourseName:{},
      courseOptions:[],
      modeOfApplication:'Uniassist',
      exams:[],
      examOptions:[
        // {title:'GRE',score:''},
        // {title:'DUOLINGO',score:''},
        {title:'IELTS',score:''},
        // {title:'GMAT',score:''},
        // {title:'TOEFL',score:''},
        // {title:'MCAT',score:''}
      ],
      editExams:[],
      editScores:[],
      editexamOptions:[
        // {title:'GRE'},
        // {title:'DUOLINGO'},
        {title:'IELTS'},
        // {title:'GMAT'},
        // {title:'TOEFL'},
        // {title:'MCAT'}
      ],
      language:null,
      languageOptions:[{value:null,text:'Select'},'English','Gujarati','Sanskrit','Hindi'],
      germanLanguage:'None',
      intake:[],
      applicationDeadline:[],
      remark:'',
      existCourseName:'',
      editCourseModal:null,
      collegeResponse:null
    }
  },
  created() {
    var self = this
    self.existCourseName = '';
    // console.log(self.getfieldAndcourseData.length,'getfieldAndcourseData')
    // self.getfieldAndcourseData.forEach(ele=>{
    //   console.log(ele,"?") 
    // })
    if(!self.docId)
    {
      self.$router.push({name:'college-mgmt'})
    }

    self.collegeDocId = self.docId

    self.$root.$on('open-edit-college',()=>{self.$bvModal.show('edit-college')})

    var countries = Country.getAllCountries()
    self.countryOptions=[{value:null,text:'Select'}]
    countries.forEach(data=>{
      self.countryOptions.push({value:data,text:data.name})
    })

    //Get Universities
    // db
    //   .collection(dbCollections.UNIVERSITIES)
    //   .orderBy('createdAt')
    //   .get()
    //   .then((querySnapshot)=>{
    //     querySnapshot.forEach(doc=>{
    //       // if(!doc.data().isDeleted)
    //         self.universities.push(doc.data().universityName)
    //         self.universityOptions.push(doc.data().universityName)
    //     })
    //   })
    //   .catch(error=>{
    //     console.log(error)
    //   })

    self.getColleges();

    self.getFieldAndCourses();
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchUsers,
      fetchCourseFromTypesense,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteField,

      addNewCourse,
      editCourse,

      isSpinner,
      refreshTable,

      collegeDocId,
      coursesTableList,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      filterCountry,
      filterState,
      filterCity,
      getfieldAndcourseData,
      handlePageChange,
      handleSortChange,
      isFetching,
      fetchedData
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      fetchCourseFromTypesense,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteField,

      addNewCourse,
      editCourse,
      collegeDocId,
      coursesTableList,

      isSpinner,
      refreshTable,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      filterCountry,
      filterState,
      filterCity,
      getfieldAndcourseData,
      handlePageChange,
      handleSortChange,
      isFetching,
      fetchedData
    }
  },
  methods:{  
    // callUpdateFunction() {
    //   updateQueryForCourses()
    // },  
    dateFormatWithMoment(date,format) {
      return moment(date).format(format)
    },
    changeOptionsValue()
    {
      var self = this

      self.examOptions.forEach(ed=>{
        self.exams.forEach(ed2=>{
          if(ed.title==ed2.title)
          {
            ed.score=ed2.score
          }
        })
      })
    },
    deleteFieldMethod(data)
    {
      var self = this
      let previous = JSON.parse(JSON.stringify(data))
      self.deleteField(data,(res,msg)=>{
        if(msg !== 'restriction'){
          if(res)
          {
            self.deleteCourse=false
            self.$root.$emit('showToastMessage',`Course ${previous.item.isDeleted ? 'enable' : 'disable'} successfully.`,'success')
          }
          else
          {
            self.deleteCourse=false
            self.$root.$emit('showToastMessage',msg,'danger')
          }
        }else{
          self.deleteCourse=false
          self.$root.$emit('showToastMessage','These college course used in another documents','danger')
        }
      })
    },
    getFieldAndCourses()
    {
      var self = this;

      //Get Fields And Course
      db
        .collection(dbCollections.FIELDANDCOURSE)
        .orderBy('createdAt')
        .get()
        .then((querySnapshot)=>{
          querySnapshot.forEach(doc=>{
            self.fieldOptionsEdit.push({text:doc.data().fieldName,value:{name:doc.data().fieldName,id:doc.data().id,courses:[]}})
              doc.data().courses.forEach(val=>{
                  self.fieldOptionsEdit[self.fieldOptionsEdit.length-1].value.courses.push({title:val.name,id:val.id})
              })
            if(!doc.data().isDeleted)
            {
              self.fieldOptions.push({text:doc.data().fieldName,value:{name:doc.data().fieldName,id:doc.data().id,courses:[]}})
              doc.data().courses.forEach(val=>{
                if(val?.isCoursesDeleted !== true) {
                  self.fieldOptions[self.fieldOptions.length-1].value.courses.push({title:val.name,id:val.id})
                }
              })
              // console.log("OP",self.fieldOptions)
              // doc.data().courses.forEach(data=>{
              //   self.courseOptions.push({title:data,value:{name:data,id:doc.data().id}})
              // })
            }
          })
        })
    },
    getIntakes()
    {
      var self = this
      // console.log('Intake',self.intake)
      // self.applicationDeadline=[]
      self.intake.forEach(data=>{
        var found = false
        self.applicationDeadline.forEach(data2=>{
          if(data2.title==data)
          {
            found = true
          }
        })
        if(!found)
        {
          self.applicationDeadline.push({title:data,date:''})
        }
      })
      self.applicationDeadline.forEach((data2,index)=>{
        var found = false
        self.intake.forEach(data=>{
          if(data2.title==data)
          {
            found = true
          }
        })
        if(!found)
        {
          self.applicationDeadline.splice(index,1)
        }
      })
    },
    getStates()
    {
      var self = this

      self.state=null
      self.city=null
      
      self.stateOptions=[{value:null,text:'Select'}]
      var states = State.getStatesOfCountry(self.country.isoCode)
      states.forEach(data=>{
        self.stateOptions.push({value:data,text:data.name})
      })
    },
    getCities()
    {
      var self = this

      self.city=null
      
      self.cityOptions=[{value:null,text:'Select'}]
      var cities = City.getCitiesOfState(self.country.isoCode,self.state.isoCode)
      cities.forEach(data=>{
          self.cityOptions.push({value:data,text:data.name})
      })
    },

    getFilterStates()
    {
      var self = this

      self.filterState=null
      self.filterCity=null
      
      self.stateOptions=[{value:null,text:'Select'}]
      var states = State.getStatesOfCountry(self.filterCountry.isoCode)
      states.forEach(data=>{
        self.stateOptions.push({value:data,text:data.name})
      })
    },
    getFilterCities()
    {
      var self = this

      self.filterCity=null
      
      self.cityOptions=[{value:null,text:'Select'}]
      var cities = City.getCitiesOfState(self.filterCountry.isoCode,self.filterState.isoCode)
      cities.forEach(data=>{
        self.cityOptions.push({value:data,text:data.name})
      })
    },

    mainFieldAndCourseData(fieldId,cb){
      // console.log(fieldId,"fieldId")
      getQuery.getDataFromRootCollectionWithDocID(dbCollections.FIELDANDCOURSE,fieldId,res=>{
        if(res){
        // console.log(res)
        cb(res)
        }else{
          cb(false)
        }
      })
    },
    getCourseDetail(data,index,e){
      var self = this;
      if(data.isDeleted == true){
        return
       }
      self.selectedCourse = {index:index,data:data}
         self.mainFieldAndCourseData(data.field_name.id,response=>{
            // self.modalSppiner = true;
            // console.log("DONE")
            if(response !== false){
            self.detailCourse.field = response.fieldName;  

            let searchInd = response.courses.findIndex(elem=>{
              return elem.id == data.course_name.id
            })
            if(searchInd > -1){
              self.detailCourse.course = response.courses[searchInd]
            }
            self.detailCourse.moa = data.mode_of_application;
            self.detailCourse.exams = data.exams
            self.detailCourse.gl = data.german_language;
            self.detailCourse.assmi_fee = data.applicationFees;
            self.detailCourse.tution_fee=data.tutionFees;
            self.detailCourse.intake=data.application_deadline;
            self.detailCourse.remark=data.remark;
            self.$bvModal.show('detail-course')
            // self.selectedCourse = data
            // self.modalSppiner = false;       
            self.editCourseModal = data
            }
          })
          // // detailCourse     
    },
    getCourseData(data,index)
    {
      
      var self = this

      if(self.$route.meta.action !== 'read'){
        self.mainFieldAndCourseData(data.field_name.id,response=>{
          if(response !== false){
            
            var ind = (self.currentPage-1) * self.perPage + index
            // console.log("CourseData",courseData)
            // db
            // .collection(dbCollections.COLLEGES)
            // .doc(self.collegeDocId)
            // .get()
            let serchQuery = {
              collectionName: `${dbCollections.COURSES}`,
              search: {
                  q: "*",
                  filter_by: `id:=${data.id}`,
                  per_page: 1,
              }
            };
            getCollectionDataTypesense(serchQuery)
            .then((doc)=>{
              if(doc.hits.length) {
                var courseData = doc.hits[0].document
  
  
                let fieldNameVals = data.field_name
                let searchIndex = self.fieldOptionsEdit.findIndex(elements=>{
                  if(elements.value !== null){
                  return elements.value.id == data.field_name.id;
                  }
                })
                if(searchIndex > -1){
                  fieldNameVals = self.fieldOptionsEdit[searchIndex].value
                }
                fieldNameVals.name = response.fieldName;
  
  
                self.fieldName=  data.field_name
                self.courseName=courseData.courseName
                self.oldCourseName=courseData.courseName
                // self.course=courseData.course_name
                self.courseOptions=courseData.fieldName.courses
                self.modeOfApplication=courseData.modeOfApplication
                self.exams=courseData.exams
                self.examOptions.forEach(ed=>{
                  self.exams.forEach(ed2=>{
                    if(ed.title==ed2.title)
                    {
                      ed.score=ed2.score
                    }
                  })
                })
                self.language=courseData.language
                self.germanLanguage=courseData.germanLanguage
                self.intake=courseData.intake
                self.applicationDeadline=courseData.applicationDeadline
                self.remark=courseData.remark
  
                self.applicationFees=courseData.applicationFees
                self.tutionFees=courseData.tutionFees
                self.$bvModal.show('edit-course')
              }
            })
            .catch(error=>{
              console.log(error)
            })


          // let fieldNameVals = data.field_name
          // let searchIndex = self.fieldOptions.findIndex(elements=>{
          //   if(elements.value !== null){
          //   return elements.value.id == data.field_name.id;
          //   }
          // })
          // if(searchIndex > -1){
          //   fieldNameVals = self.fieldOptions[searchIndex].value
          // }
          // fieldNameVals.name = response.fieldName;
          // self.fieldName= fieldNameVals ? fieldNameVals : data.field_name
          // self.courseName=data.course_name
          // self.oldCourseName=data.course_name
          // // self.course=data.course_name
          // self.courseOptions= data.field_name.courses
          // self.modeOfApplication=data.mode_of_application
          // self.exams=data.exams
          // self.examOptions.forEach(ed=>{
          //   self.exams.forEach(ed2=>{
          //     if(ed.title==ed2.title)
          //     {
          //       ed.score=ed2.score
          //     }
          //   })
          // })
          // self.language=data.language
          // self.germanLanguage=data.german_language
          // self.intake=data.intake
          // self.applicationDeadline=data.application_deadline
          // self.remark=data.remark

          // self.applicationFees=data.applicationFees
          // self.tutionFees=data.tutionFees
          // self.editCourseModal = true;
          // console.log(self.editCourseModal,"editCourseModal")
        }
      })      
      }
    },
    resetData()
    {
      var self=this
      
      // self.refreshTable(res=>{
      //   console.log("refresh")
      // })
      self.collegeName=self.collegeResponse.collegeName
      self.clgOldName=self.collegeResponse.collegeName
      self.courses=self.collegeResponse.courses
      self.universityName=self.collegeResponse.universityName
      self.addressLine1=self.collegeResponse.addressLine1
      self.addressLine2=self.collegeResponse.addressLine2
      self.pincode=self.collegeResponse.pincode
      self.country=self.collegeResponse.country
      self.getStates();
      self.state=self.collegeResponse.state
      self.getCities();
      self.city=self.collegeResponse.city
    },
    resetCorseData(from=false)
    {
      var self = this
      if(from == false) {
        self.refreshTable(()=>{
          // console.log("refresh",res)
        })
      }

      self.fieldName=null
      // self.fieldOptions= [{value:null,text:'Select'}]
      self.course=[]
      self.modeOfApplication='Uniassist'
      self.exams= []
      self.examOptions=[
        // {title:'GRE',score:''},
        // {title:'DUOLINGO',score:''},
        {title:'IELTS',score:''},
        // {title:'GMAT',score:''},
        // {title:'TOEFL',score:''},
        // {title:'MCAT',score:''}
      ]
      self.language=null
      self.germanLanguage='None'
      self.intake=[]
      self.applicationDeadline=[]
      self.remark=''

      self.applicationFees=''
      self.tutionFees=''
    },
    handleRowClick(data)
    {
      this.$router.push({name:'field-course-info',params: { id: data.id,name:data.field_name }})
    },
    isOnlyDecimal(event) {
      ValidationFunction.OnlyFloatWithNumber(event);
    },
    isOnlyInteger(event) {
      ValidationFunction.OnlyIntegerNumber(event);
    },

    getColleges()
    {
      try {
        var self = this
        getCollectionData(`${dbCollections.COLLEGES}/${self.docId}`)
        .then(async(collgeDocRef)=>{
          if(collgeDocRef.exists) {
            self.collegeResponse = collgeDocRef.data()
            self.collegeName=collgeDocRef.data().collegeName
            self.clgOldName=collgeDocRef.data().collegeName
            // self.courses=collgeDocRef.data().courses
            self.universityName=collgeDocRef.data().universityName
            self.addressLine1=collgeDocRef.data().addressLine1
            self.addressLine2=collgeDocRef.data().addressLine2
            self.pincode=collgeDocRef.data().pincode
            self.country=collgeDocRef.data().country
            self.getStates();
            self.state=collgeDocRef.data().state
            self.getCities();
            self.city=collgeDocRef.data().city
            // let serchQuery = {
            //   collectionName: `${dbCollections.COURSES}`,
            //   search: {
            //       q: "*",
            //       filter_by: `collegeId:=${self.docId}`,
            //       per_page: 250,
            //   }
            // }
            // let resultTypesense = await getCollectionDataTypesense(serchQuery)
            // console.log(resultTypesense);
            // self.courses = resultTypesense.hits.map((e)=>e.document);
          }
        })
        .catch((err)=>{
          console.error('ERROR IN getCollge',err)
        })
      } catch (error) {
        console.error('ERROR getCollge:',error)
      }
    },
    editCollege(data, callback)
    {
      var self = this
      // console.log("Edit College",data)
      db
        .collection(dbCollections.COLLEGES)
        .doc(self.docId)
        .update({
          updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
          city:data.city,
          state:data.state,
          country:data.country,

          addressLine1:data.addressLine1,
          addressLine2:data.addressLine2,
          pincode:data.pincode,
        })
        .then(()=>{
          callback(true,'success')
        })
        .catch(error =>{
          console.log(error)
          callback(false,error.message)
        })
    },
    editSelectedCollege(e)
    {
      var self = this

      // console.log(e)

      self.$refs.personalInfoForm.validate().then(success=>{
        if(success)
        {
          self.modalSppiner = true;
          // console.log( self.modalSppiner," self.modalSppiner")
          var Unidata={
            addressLine1:self.addressLine1,
            addressLine2:self.addressLine2,
            pincode:self.pincode,
            state:self.state,
            city:self.city,
            country:self.country
          }
          self.editCollege(Unidata,(res,msg)=>{
            // self.getColleges()
            if(res)
            {
              self.collegeResponse = {...self.collegeResponse,...Unidata};
              self.$root.$emit('showToastMessage','College edited successfully.','success')
              self.modalSppiner = false;
            }
            else
            {
              self.$root.$emit('showToastMessage',msg,'danger')
              self.modalSppiner = false;
            }            
            self.$bvModal.hide('edit-college')
          })
        }
        else
        {
          e.preventDefault()
        }
      });
    },
    editSelectedCourse(e)
    {
      var self = this
      console.log(self.selectedCourse,'self.selectedCourse');


      self.$refs.addCourseForm.validate().then(success=>{
        if(success)
        {
          self.modalSppiner = true;

          self.exams.forEach(val=>{
            if(val.score.value!=undefined)
              val.score = val.score.value
          })

          var Unidata={}
          // self.course.forEach(data=>{
            Unidata={
              index:self.selectedCourse.index,
              'id':self.selectedCourse.item.id,
              'fieldName':self.fieldName,
              'courseName':self.courseName,
              'language':self.language,
              'modeOfApplication':self.modeOfApplication,
              'intake':self.intake,
              'applicationDeadline':self.applicationDeadline,
              'germanLanguage':self.germanLanguage,
              'exams':self.exams,
              'remark':self.remark,
              'applicationFees':self.applicationFees,
              'courseUniqueId':self.selectedCourse?.item ? self.selectedCourse?.item.courseUniqueId : self.selectedCourse?.data.courseUniqueId,
              'tutionFees':self.tutionFees,
              'oldObject':JSON.parse(JSON.stringify(self.selectedCourse?.item))
            }
          //   })
          // })
          self.editCourse(Unidata,(res,msg)=>{
            // self.getColleges()
            if(res)
            {
              self.modalSppiner = false;
              self.$root.$emit('showToastMessage','Course edited successfully.','success')
            }
            else
            {
              self.modalSppiner = false;
              self.$root.$emit('showToastMessage',msg,'danger')
            }
            self.$bvModal.hide('edit-course')
          })
        }
        else
        {
          self.modalSppiner = false;
          e.preventDefault()
        }
      });
    },
    makeUniqId(length=18) {
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        var resultStr = ""
        for ( var i = 0; i < Number(length); i++ ) {
            resultStr += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return resultStr
    },
    //Add Course
    addCourse(e)
    {
      var self = this
      self.$refs.addCourseForm.validate()
      .then(async (success)=>{
        const res = await this.checkCourse(self.collegeResponse.id);
        if(success)
        {
          if(res == true) {
            self.existCourseName = 'course alredy exist';
            return;
          }
          let collegeObject = {
            collegeId:self.collegeResponse.id,
            universityId:self.collegeResponse.universityId,
            universityName:self.collegeResponse.universityName,
            collegeName:self.collegeResponse.collegeName
          }
          this.modalSppiner = true;
          // console.log('Success',success)
          var courseData=[]

          self.exams.forEach(val=>{
            if(val.score.value!=undefined)
              val.score = val.score.value
          })
          self.course.forEach(data=>{
            let uniqueCId = self.makeUniqId(24);
            courseData.push({
              'fieldName':self.fieldName,
              'courseUniqueId': uniqueCId,
              'courseName':data,
              'language':self.language,
              'modeOfApplication':self.modeOfApplication,
              'intake':self.intake,
              'applicationDeadline':self.applicationDeadline,
              'germanLanguage':self.germanLanguage,
              'exams':self.exams,
              'remark':self.remark,
              'applicationFees':self.applicationFees,
              'tutionFees':self.tutionFees
            })
          })
          self.addNewCourse(courseData,(res,msg)=>{
            // let typesenseData = []
            async function addRecordsToFirebase(collectionName, data) {
                try {
                    const batch = db.batch();
                    const collectionRef = db.collection(collectionName);

                    data.forEach(item => {
                        const docRef = collectionRef.doc(item.id);
                        // typesenseData.push({...item,id: docRef.id, updatedAt:new Date().getTime()/1000,createdAt:new Date().getTime()/1000,...collegeObject});
                        batch.set(docRef, {...item,id: docRef.id,updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),createdAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),...collegeObject});
                    });

                    await batch.commit();
                } catch (error) {
                    console.error('Error adding records to Firebase', error);
                    throw error;
                }
            }

            // async function addRecordsToTypesense(collectionName, records) {
            //   console.log('adding records to typesense', records);
            //   try {
            //     await client.collections(collectionName).documents().import(records);
            //   } catch (error) {
            //     console.error('Error adding records to Typesense:', error);
            //     throw error;
            //   }
            // }
            addRecordsToFirebase('courses',courseData)
            // addRecordsToTypesense('courses',typesenseData)
            self.resetCorseData()
            // self.getColleges();
            if(res)
            {
              // courseData.forEach((e)=>{
              //   self.collegeResponse.courses.push(e)
              // })
              self.existCourseName = '';
              this.modalSppiner = false;
              self.$root.$emit('showToastMessage','New course added successfully.','success')
            }
            else
            {
              this.modalSppiner = false;
              self.$root.$emit('showToastMessage',msg,'danger')
            }
            self.$bvModal.hide('add-course')
          })
          // console.log('Courses',courseData)
        }
        else
        {
          this.modalSppiner = false;
          // console.log('Success',success)
          e.preventDefault()
        }
      })
    },
    checkCourse(id) {
      return new Promise((resolve,reject)=>{
        var documentIds = this.course.map((e)=>e.id);
  
        function chunkArray(myArray, chunk_size) {
            var index = 0;
            var arrayLength = myArray.length;
            var tempArray = [];
  
            for (index = 0; index < arrayLength; index += chunk_size) {
                var myChunk = myArray.slice(index, index + chunk_size);
                tempArray.push(myChunk);
            }
  
            return tempArray;
        }
  
        var chunks = chunkArray(documentIds, 5);
  
        var promises = [];
  
        chunks.forEach(chunk => {
            var promise = db.collection(dbCollections.COURSES).where('collegeId','==',id).where('courseName.id', 'in', chunk).get();
            promises.push(promise);
        });
  
        Promise.all(promises).then(snapshotArray => {
            var mergedResults = [];
            snapshotArray.forEach(snapshot => {
                snapshot.docs.forEach(doc => {
                    mergedResults.push(doc.data());
                });
            });
            if(mergedResults.length) {
              resolve(true)
            } else {
              resolve(false)
            }
        }).catch(error => {
            console.error("Error fetching documents:", error);
        });
      })

      // return new Promise((resolve,reject)=>{
      //   db.collection(dbCollections.COURSES).where('collegeId','==',id).where('courseName.id','in',this.course.map((e)=>e.id)).get().then((resultData)=>{
      //     console.log(resultData);
      //     if(resultData.empty == true) {
      //       resolve(false)
      //     } else {
      //       resolve(true)
      //     }
      //   }).catch((err)=>{
      //     console.log(err)
      //     reject(e)
      //   })
      // })
    },
    // let searchParameters = {
        //     collectionName: `${dbCollections.COURSES}`,
        //     search: {
        //         q:"*",
        //         filter_by: `collegeId:=${id} && courseName.id:=[${this.course.map((e)=>e.id)}]`,
        //         per_page: 1,
        //     }
        // }
        // getCollectionDataTypesense(searchParameters).then((resdata)=>{
        //   console.log(resdata,'resdata')
        //   if(resdata.found == 0) {
        //     resolve(false)
        //   } else {
        //     resolve(true)
        //   }
        // }).catch((e)=>{
        //   reject(e)
        // })
    courseSameNameValidation(event)
    {
      var self=this
      // var found = false
      let errMsg = ''
      self.existCourseName = errMsg
      // console.log(event,"course")
      // if(self.courses.length > 0){
      //   self.existCourseName = ''
      //   self.courses.forEach(val=>{
      //     let findIndex = event.findIndex(element=>{
      //       return element.id == val.courseName.id
      //     })          
      //     if(findIndex > -1){
      //       errMsg = 'course alredy exist'
      //       self.existCourseName = errMsg;
      //       return;
      //     }
      //   })      
      // }
      // console.log(self.courses,"course") //CURRENT COLLEGE COURSES
      // console.log(errMsg,"??????",self.countryOptions)
    },
    changeBirthDate(event,index){                    
            var self = this
            var selectedDate = event.split('-')
            // console.log(selectedDate[0])
            if(selectedDate[0].length>4)//If Year not valid
            {
              self.applicationDeadline[index].date = '';
              return;
            }
            // else if(selectedDate[0]==maxDate[0])
            // {
            //     if(selectedDate[1]>maxDate[1])//If month not valid
            //     {
            //         self.dob = '';
            //         return;
            //     }
            //     else
            //     {
            //         if(selectedDate[1]==maxDate[1] && selectedDate[2]>maxDate[2])//If date not valid
            //         {
            //             self.dob = '';
            //             return;
            //         }
            //     }
            // }
        },
        addZero(val)
        {
          if(val<10)
          {
              return `0`+val
          }
          else
          {
              return val
          }
        },
        getMaxDate()
        {
          return `${this.addZero(new Date().getFullYear())}-${this.addZero(new Date().getMonth()+1)}-${this.addZero(new Date().getDate())}`
        },
  },

  // computed:{
    // duplicateCollegeName()
    // {
    //   var self = this
    //   var found = false

    //   self.colleges.forEach((data,index)=>{
    //     if(data.toLowerCase().trim()==self.collegeName.toLowerCase().trim())
    //     {
    //       found = true
    //     }
    //   })

    //   return found;
    // },
    // duplicateEditCollegeName()
    // {
    //   var self = this
    //   var found = false

    //   self.colleges.forEach((data,index)=>{
    //     if(data.toLowerCase().trim()==self.collegeName.toLowerCase().trim() && index != self.selectedCourse.index && self.clgOldName.toLowerCase().trim() != self.collegeName.toLowerCase().trim())
    //     {
    //       found = true
    //     }
    //   })

    //   return found;
    // },
    // duplicateCourseName()
    // {
    //   var self=this
    //   var found = false

    //   self.coursesTableList.forEach((data)=>{
    //     if(self.courseName && data.fieldName.name)
    //     {
    //       if(data.courseName.title == self.courseName.title && data.fieldName.name == data.fieldName.name && self.courseName.title!=self.oldCourseName.title)
    //       {
    //         found = true
    //       }
    //     }
    //   })

    //   // console.log()

    //   return found
    // }
  // },
}
</script>
<style>
.focusRemoveToggle:focus {
  outline: none !important;
}
.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}
/* .position-relative.table-responsive tbody tr td{
  white-space: nowrap !important;
} */

.s_modal_title{
    width: 100%;
    
    font-weight: 600;
    font-size: 20px;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.position-relative.college_info_table.table-responsive {
    padding: 0px!important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
